.homeWrapper {
    display: grid;
    width: 100%;
    grid-template-rows: minmax(500px, calc(35vmin + 100px)) min-content min-content min-content 1rem min-content min-content min-content min-content min-content min-content;
    grid-template-columns: 1rem calc(100% - 2rem) 1rem;
    grid-template-areas:
        "search search search"
        ". featured ."
        ". searchOptions ."
        ". flexible ."
        ". . ."
        ". blog ."
        ". latest ."
        "schemeSearch schemeSearch schemeSearch"
        "portfolios portfolios portfolios"
        "news news news"
        "signup signup signup"
        ". crmp ."
        ". popular .";
}

.schemeSearch {
    grid-area: schemeSearch;
    padding: 2rem 0 2rem;
    text-align: center;
    background-color: rgb(242, 242, 242);
    display: grid;
    grid-template-columns: max-content;
    justify-content: center;
    align-content: center;
}

.schemeSearch h2 {
    padding-bottom: 1rem;
}

.homeWrapper:before {
    content: "";
    position: fixed;
    z-index: 70;
    width: 100%;
    height: 70px;
    background-color: transparent;
    transition: background-color 200ms;
}

.rounded {
    border-radius: 5px;
}

.FourColumn {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem 1rem;
    grid-auto-flow: row;

}

.Heading2 {
    font-size: 1.2rem;
}

.subHead {
    max-width: 650px;
    font-size: 0.95rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: -0.5rem;
    margin-bottom: 0rem;
}

.FourColumnOld a {
    display: block;
}

.FourColumnOld a:after {
    content: "";
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    padding-bottom: 75%;
}

.FourColumn h3 {
    font-weight: normal;
}

.Heading2,
.subHead {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    color: rgb(52, 52, 52);

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235);
    }
}

.homeSearch {
    grid-area: search;
    position: relative;
    z-index: 60;
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 100%;
    justify-content: center;
    align-content: center;
    padding: 70px 1rem 35px 1rem;

}

.homeSearch input {
    @media (prefers-color-scheme: dark) {
        color: rgb(34, 42, 48);
    }
}

.SearchInner {
    position: relative;
    z-index: 10;
}

.SearchInner h1 {
    color: #FFF;
    text-align: center;
    font-size: 2rem;
}

.SearchInner p {
    margin-left: auto;
    margin-right: auto;
    color: #FFF;
    max-width: 655px;
    text-align: center;
}

.Featured,
.FeaturedNew {
    grid-area: featured;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Featured a {
    display: block;
}

.SoapboxBanner {
    grid-area: destinations;
    position: relative;
    overflow: hidden;
}

.SoapboxBannerAd1 {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-rows:
        min-content min-content 1fr min-content 1.5rem min-content;
    grid-template-columns: 1fr;
    justify-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    background: rgba(80, 80, 80, 0.5);
    color: #fff;
}

.SoapboxBannerAd1 h2 {
    grid-row: 1;
    font-size: 17.6px;
}

.SoapboxBannerAd1 h3 {
    grid-row: 2;
    color: #fffdfd;
    font-size: 0.9rem;
    opacity: 0.5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 10px;
}

.SoapboxBannerAd1 p {
    grid-row: 4;
    margin: 0;
    font-size: 14.4px;
    max-width: 460px;
    line-height: 130%;
}

.SoapboxBannerAd1 a {
    grid-row: 6;
    font-size: 0.9rem;
}

.SoapboxBannerAd2 {
    z-index: 1;
    object-fit: cover;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    object-fit: cover;
    filter: blur(2px);
}

.SoapboxBannerAd2 picture,
.SoapboxBannerAd2 img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    object-fit: cover;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.BlogLogo {
    position: absolute;
    z-index: 20;
    top: 1.5rem;
    left: 1.5rem;
    color: #fff;
    font-weight: 700;
    font-size: 17.6px;
}


.blog :after {
    content: "";
    display: none;
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.65) 100%);
}

.blog {
    grid-area: blog;
    position: relative;
}

.blog div :nth-of-type(2) {
    z-index: 10;
}

.blogDiv {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 20;
    display: grid;
    justify-items: flex-start;
    grid-template-rows:
        min-content min-content;
    grid-gap: 1.5rem;
    padding: 1.5rem 1.5rem 1rem;
    color: #fff;
}

.blogDiv p {
    margin: 0;
    font-size: 14.4px;
    max-width: 460px;
    line-height: 130%;
}

.blogDiv a {
    font-size: 0.9rem;
}

.latest {
    grid-area: latest;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.latest a {
    display: none;
}

.latest a:nth-of-type(1),
.latest a:nth-of-type(2),
.latest a:nth-of-type(3),
.latest a:nth-of-type(4) {
    display: block;
}

@media screen and (min-width: 768px) {

    .latest a:nth-of-type(5),
    .latest a:nth-of-type(6) {

        display: block;
    }
}

@media screen and (min-width: 950px) {

    .latest a:nth-of-type(7),
    .latest a:nth-of-type(8) {

        display: block;
    }
}


.latestLink {
    display: block;
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: normal;
}

.latest div :after {
    display: none;
}

.portfolios {
    grid-area: portfolios;
    padding: 0 0 2rem;
    text-align: center;
    background-color: rgb(242, 242, 242);

}


@media (prefers-color-scheme: dark) {
    .portfolios,
    .schemeSearch {
        background-color: rgb(29, 37, 44);
    }
}

.portfolios h2 {
    position: relative;
    z-index: 2;
    text-align: center;
    color: rgb(128, 128, 128);

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235);
    }

    font-size: 1.2rem;
}

.portfolios a {
    max-width: 160px;
    margin: auto;
}


.crmp {
    grid-area: crmp;
    position: relative;
    display: grid;
    grid-template-columns: minmax(20px, 562px);
    grid-template-rows:
        max-content max-content;
    justify-content: center;
    grid-gap: 1.5rem 1rem;
    overflow: hidden;
    margin-bottom: 2rem;


}

.crmp h2 {
    position: absolute;
    bottom: 100%;
}

.crmp a {
    position: relative;
    color: white;
    width: 100%;
}

.crmp a:before {
    content: "";
    position: relative;
    color: white;
    display: block;
    width: 100%;
    max-width: 562px;
    padding-bottom: 62.2776%;
}

.heroImage {
    display: block;
    position: absolute;
    inset: 0px;
    margin: 0px;
    object-fit: cover;
}


.heroImage img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}

.advert {
    position: relative;
    background: rgb(52, 136, 175);
    border-radius: 5px;
}

.searchOptions {
    grid-area: searchOptions;
    display: grid;
    grid-template-columns: minmax(20px, 562px);
    grid-template-rows:
        max-content max-content;
    justify-content: center;
    grid-gap: 1.5rem 1rem;
    overflow: hidden;
}

.searchOptionsContainer {
    border: solid 1px #D0D0D0;
    padding: 1rem;
    border-radius: 8px;

}

.nearMe {
    background: url(/images/nearMeBackground.svg) no-repeat right;
}

.forSale {
    background: url(/images/forSaleBackground.svg) no-repeat right;
}

.searchOptionsContainer h2 {
    font-size: 20px;
}

.searchOptionsContainer p {
    line-height: 25px;
    max-width: 389px;
}


.PictureCardImage {
    position: relative;
    grid-area: image;
}

.PictureCardInfo {
    grid-area: content;
    padding: 1rem;
    min-height: 159px;
}

.PictureCardContainer {
    display: grid;
    grid-template:
        "image" 200px
        "content" min-content;
    border: 1px solid #D0D0D0;
    border-radius: 8px;
}

.PictureCardContainer img {
    border-radius: 7px 7px 0 0;
}

.PictureCardContainer p {
    margin: 5px 0;
}

.PictureCardContainer h3 {
    font-weight: bold;
    border-bottom: 2px solid #BBBBBB;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.PictureCardContainer h3 span {
    font-weight: 400;
    color: #575757;
    font-size: 16px;

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235);
    }
}

@media screen and (min-width: 768px) {
    .heroImage {
        background: url("https://neo.completelyretail.co.uk/home/search/img_2560.webp") center center no-repeat;
    }

    .homeSearch {
        grid-template-columns: max-content !important;
        padding: 100px 1rem 50px 1rem !important;
    }

    .homeWrapper {
        grid-template-columns: 1rem 1rem calc(40% - 1.5rem) calc(60% - 1.5rem) 1rem;
        grid-template-rows: minmax(500px, calc(35vmin + 100px)) min-content min-content min-content min-content min-content min-content min-content;

        grid-template-areas:
            "search search search search search"
            ". featured featured featured ."
            ". searchOptions searchOptions searchOptions ."
            ". flexible flexible flexible ."
            ". latest latest latest ."
            "schemeSearch schemeSearch schemeSearch schemeSearch schemeSearch"
            "portfolios portfolios portfolios portfolios portfolios"
            "news news news news news"
            "signup signup signup signup signup"
            ". crmp crmp crmp ."
            ". popular popular popular .";
    }

    .homeWrapper:before {
        height: 100px;
    }

    .FourColumn {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .Heading2,
    .subHead {
        grid-column-end: 4;
    }

    .Featured a:nth-of-type(3) {
        display: none;
    }

    .latestLink {
        grid-column-end: 4;
    }
}

@media screen and (min-width: 800px) {
    .homeWrapper {
        grid-template-columns: 1rem calc(40% - 1.5rem) 1rem calc(60% - 1.5rem) 1rem;
    }
}

@media screen and (min-width: 1172px) {
    .homeWrapper {
        grid-template-columns:
            1fr calc((1140px * (3 / 5)) - 0.5rem) 1rem calc((1140px * (2 / 5)) - 0.5rem) 1fr;
    }
}


@media screen and (min-width: 950px) {
    .FourColumn {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .Heading2,
    .subHead {
        grid-column-end: 5;
    }

    .Featured a:nth-of-type(3) {
        display: block;
    }


    .latestLink {
        grid-column-end: 5;
    }
}

@media screen and (min-width: 500px) {
    .Heading2 {
        font-size: calc(1.2rem + (25.6 - 19.2) * (100vw - 500px) / (1200 - 500));
    }

    .SearchInner h1 {
        font-size: calc(2rem + (38.4 - 32) * (100vw - 500px) / (1200 - 500));
    }

    .portfolios h2 {
        font-size: calc(1.2rem + (25.6 - 19.2) * (100vw - 500px) / (1200 - 500));
    }
}

@media screen and (min-width: 1200px) {
    .Heading2 {
        font-size: 1.6rem;
    }

    .SearchInner h1 {
        font-size: 2.4rem;
    }

    .portfolios h2 {
        font-size: 1.6rem;
    }
}

@media screen and (min-width: 968px) {
    .searchOptions {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 209px;
    }

    .crmp {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: minmax(350px, 27.5vmin);
    }
}

@media screen and (max-width: 968px) {
    .FeaturedNew a:nth-of-type(3) {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .heroImage {
        background-color: #333;
    }

    .heroImage picture {
        display: none;
    }

    .PictureCardContainer {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .FeaturedNew a:nth-of-type(3) {
        display: grid;
    }

    .schemeSearch {
        grid-template-columns: 1fr;
        padding: 2rem 1rem;
    }
}